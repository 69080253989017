import { REGEX_OPTIONS } from '@utilsApp/index';

export const INPUT_REQUIRED = {
  required: {
    value: true,
    message: 'form_errors.input_is_required'
  }
};

export const EMAIL_PATTERN = {
  pattern: {
    value: REGEX_OPTIONS.email,
    message: 'form_errors.email_pattern'
  }
};

export const PASSWORD_PATTERN = {
  pattern: {
    value: REGEX_OPTIONS.password,
    message: 'form_errors.password_pattern'
  }
};

export const DATE_VALID_PATTERN = {
  pattern: {
    value: REGEX_OPTIONS.date_valid,
    message: 'form.error-date-valid-pattern'
  }
};
