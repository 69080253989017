//* External
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import { omit } from 'lodash';

//* App Custom
import { cleanObject } from '@utilsApp/index';
import { LoaderWrapper, OffCanvasForm } from '../index';
import Counter from './Counter/Counter';
import Pagination from './Pagination/Pagination';
import './dataPaginated.scss';

const DataPaginated = ({
  registries,
  renderComponent,
  params,
  setParams = () => {},
  filters = () => [],
  filterParams = {},
  registriesPerPage = [5, 10, 15, 20]
}) => {
  const filterData = (inputData) => {
    const cleanData = cleanObject(inputData);

    let filteredData = { ...cleanData };

    Object.keys(inputData).forEach((key) => {
      if (
        typeof inputData[key] === 'object' &&
        !Array.isArray(inputData[key])
      ) {
        filteredData = {
          ...filteredData,
          ...inputData[key]
        };
        filteredData = omit(filteredData, [key]);
      }
    });

    setParams({ ...filteredData, page: 0, length: params.length });
  };

  return (
    <div className="dataPaginated">
      <div className="mb-3">
        <OffCanvasForm
          inputs={filters}
          inputParams={filterParams}
          defaultValues={params}
          onSubmit={filterData}
        />
      </div>
      <LoaderWrapper
        isSearching={registries.isSearching}
        data={registries.data}
      >
        {renderComponent}
        <div className="dataPaginated_footer">
          <Row className="my-3">
            <Counter registries={registries} params={params} />
            <Pagination
              registries={registries}
              registriesPerPage={registriesPerPage}
              params={params}
              setParams={setParams}
            />
          </Row>
        </div>
      </LoaderWrapper>
    </div>
  );
};

DataPaginated.propTypes = {
  registries: PropTypes.exact({
    isSearching: PropTypes.bool,
    totalCount: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  renderComponent: PropTypes.node.isRequired,
  params: PropTypes.object.isRequired,
  setParams: PropTypes.func.isRequired,
  filters: PropTypes.func,
  filterParams: PropTypes.object,
  registriesPerPage: PropTypes.arrayOf(PropTypes.number)
};

export default DataPaginated;
