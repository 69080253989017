export default {
  date_valid: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
  decimal_valid: /^-?\d+(\.\d+)?$/,
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  number_length: /^[0-9]{1,10}$/g,
  number_valid: /^[0-9]*$/g,
  number_valid_weight: /^[0-9]+(\.[0-9]{1,2})?$/,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g,
  phone_length: /^(?:\d{7}|3\d{9})$/,
  size_meter_valid: /^(?:(?:0\.[5-9]\d*|1\.\d*|2\.[0-4]\d*)|2\.5)$/,
  size_centimeter_valid: /^(50|2[0-9]{2}|1\d{2}|[5-9]\d)$/
};
