/* eslint-disable no-dupe-keys */
export default {
  AUTH: {
    LOGIN: '/api/token/',
    USERS: '/api/core/users/',
    REGISTER: '/api/core/users/',
    ACTIVATE_USER: '/api/core/users/activate/',
    REFRESH_TOKEN: '/api/token/refresh/',
    RESET_PASSWORD: '/api/password_reset/',
    RESET_PASSWORD_CONFIRMATION: '/api/password_reset/confirm/'
  },
  BEYOND_HEALTH: {
    AUTHORIZATIONS: '/api/beyond-health/bh-authorizations/',
    COVERAGES: '/api/beyond-health/bh-coverages/',
    PATIENTS: '/api/beyond-health/bh-patients/',
    PROVIDERS: '/api/beyond-health/bh-providers/',
    SYNC_AUTHORIZATION: '/api/beyond-health/bh-authorization-status/'
  },
  CHEMO: {
    APPLICATION: '/api/chemo/application/',
    APPLICATION_DETAIL: '/api/chemo/application-detail/',
    APPLICATION_CONFIRMED_ADMISSION:
      '/api/chemo/application_confirmed_admission/',
    APPLICATION_CONFIRMED_ADMISSIONS:
      '/api/chemo/application_confirmed_admissions/',
    APPLICATION_PROGRAMMING_MANAGER:
      '/api/chemo/application_programming_manager/',
    APPLICATION_PROGRAMMING_MANAGER_UPDATE:
      '/api/chemo/application_programming_manager_u/',
    APPLICATION_HOSPITAL_DOCTOR: '/api/chemo/application_hospital_doctor/',
    APPLICATION_HOSPITAL_DOCTOR_UPDATE:
      '/api/chemo/application_hospital_doctor_u/',
    APPLICATION_GENERAL_RESUME: '/api/chemo/application/',
    APPLICATIONS_GENERAL_RESUME: '/api/chemo/applications/',
    APPLICATION_INDUCED_DEMAND: '/api/chemo/application_induced_demand/',
    APPLICATION_INDUCED_DEMAND_UPDATE:
      '/api/chemo/application_induced_demand_u/',
    APPLICATION_PHARMACEUTICAL: '/api/chemo/application_pharmaceutical/',
    APPLICATION_PHARMACEUTICAL_UPDATE:
      '/api/chemo/application_pharmaceutical_u/',
    APPLICATION_ROOM_MANAGER: '/api/chemo/application-room-manager/',
    APPLICATIONS_BY_ROOM: '/api/chemo/applications-by-room/',
    CANCELATION_REASONS: '/api/chemo/cancel-application/',
    APPLICATION_CONFIRMED_REGENT_PHARMACEUTICAL:
      '/api/chemo/application-list-pharmacy-regent/',
    APPLICATION_CONFIRMED_VALIDATION_REGENT_PHARMACEUTICAL:
      '/api/chemo/validations/regent_pharmacy/',
    APPLICATION_CONFIRMED_CHEMIST_PHARMACEUTICAL:
      '/api/chemo/patient_confirmed/',
    APPLICATION_CONFIRMED_RESUME: '/api/chemo/patient_confirmed/',
    BILLING_ADMISSIONS_VALIDATION: '/api/chemo/billing-admissions/',
    BILLING_CREATE_PATIENT: '/api/chemo/billing-create-patient/',
    BILLING_PHARMACY_REGENT_VALIDATION: '/api/chemo/billing-pharmacy-regent/',
    BILLING_ROOM_HEAD_VALIDATION: '/api/chemo/billing-room-head/',
    CANCEL_CYCLE_REASONS: '/api/chemo/reason-cancel-cycle/',
    CHAIR: '/api/chemo/chair/',
    CHAIR_AVAILABILITY_RANGE: '/api/chemo/chair-availability-range/',
    CHAIR_AVAILABILITY: '/api/chemo/chair-availability/',
    CYCLE: '/api/chemo/cycle/',
    CYCLE_CANCELED: '/api/chemo/cycle-canceled/',
    CYCLE_HISTORY_MEDICAL_ORDER: '/api/chemo/cycle-history-medical-order/',
    CYCLE_PENDING_AUTHORIZATION: '/api/chemo/cycle-pendient-authorization/',
    DISCOVERY: '/api/chemo/discovery/',
    DOSE_BY_DAY: 'api/chemo/dose-by-day/',
    HISTORY_LOG_MEDICAL_ORDER: '/api/chemo/history-log-medical-order/',
    INFUSER_BOOKINGS: '/api/chemo/infuser-bookings-availabilities/',
    MANAGE_BILLING_NUMBER: '/api/chemo/billing-managed-billing/',
    MEDICAL_ORDER: '/api/chemo/medical-order/',
    MEDICAL_ORDER_APPLICATION_BY_PRODUCTION_ORDER:
      '/api/chemo/medical-order-application-by-production-order/',
    MEDICAL_ORDER_MEDICINE_PROTOCOL:
      '/api/chemo/medical-order-medicine-protocol/',
    MEDICAL_ORDERS_CUM: '/api/chemo/cum/',
    MEDICAL_ORDERS_CUP: '/api/chemo/cup/',
    MEDICAL_ORDERS_BY_DATE: '/api/chemo/medical-order-by-date/',
    MEDICINE_MEDICAL_ORDERS_APPLICATION:
      '/api/chemo/medical-order-application/',
    MEDICINE_MEDICAL_ORDERS: '/api/chemo/medicine-medical-orders/',
    MEDICINE_MEDICAL_ORDERS_APPLICATION:
      '/api/chemo/medical-order-application/',
    MEDICAL_ORDER_APPLICATION_BY_PRODUCTION_ORDER_CREATE:
      'api/chemo/medical-order-application-by-production-order-create/',
    MEDICINE_PROTOCOL: '/api/chemo/medicine-protocol/',
    MEDICINE_PROTOCOLS: '/api/chemo/medicine-protocols/',
    MEDICINE_PROTOCOLS_ALL: '/api/chemo/medicine-protocols-all/',
    MEDICINE_PROTOCOLS_FILTER: '/api/chemo/medicine-protocols-filter/',
    MULTIPLE_MEDICAL_ORDERS: '/api/chemo/multiple-medical-orders/',
    PROTOCOLS: '/api/chemo/protocols/',
    REPORT_MEDICAL_ORDER_CHEMIST_PHARMACEUTICAL:
      '/api/report/medical-order-medicine-reports/',
    REPORT_MIX_CENTER_CHEMIST_PHARMACEUTICAL:
      '/api/report/mix-medicine-reports/',
    REPORT_APPLICATIONS_ORDER_CHEMIST_PHARMACEUTICAL:
      '/api/report/application-reports/',
    REPROGRAMMING_PATIENT: '/api/chemo/re-programming-patient/',
    ROOM: '/api/chemo/room/',
    PATIENT: '/api/chemo/patient/',
    SERVICE: '/api/chemo/service/',
    SYNCHRONIZE_AUTHORIZATION: '/api/chemo/synchronize-authorization/',
    TREATMENT_FREQUENCY_BY_DAY: '/api/chemo/treatment-frequency-by-day/',
    UPDATE_CYCLE_MEDICAL_ORDERS: '/api/chemo/cycle/medical-orders/',
    UPDATE_APPLICATION_MEDICAL_ORDERS:
      '/api/chemo/application-update-medical-orders/',
    VALIDATION_CHEMO_HOSPITAL_DOCTOR: '/api/chemo/validations/hospital-doctor/',
    VALIDATION_CHEMO_INDUCED_DEMAND:
      '/api/chemo/validations/induced-demand-boss/',
    VALIDATION_CHEMO_PHARMACEUTICAL:
      '/api/chemo/validations/pharmaceutical-chemo/',
    VALIDATION_CHEMO_PROGRAMMING_MANAGER:
      '/api/chemo/validations/programming-manager/',
    VALIDATION_CHEMO_VALIDATION_ADMISSION:
      '/api/chemo/billing-validation-admission/',
    VALIDATION_CHEMO_BILL: '/api/chemo/billing-validation-bill/',
    VALIDATION_CHEMO_PHARMACY: '/api/chemo/billing-validation-pharmacy/',
    VALIDATION_CHEMO_ROOM: '/api/chemo/billing-validation-room/',
    VALIDATION_CHEMO_MIXING: '/api/chemo/billing-validation-mixing-plant/',
    VENOUS_ACCESS: '/api/chemo/venouss_access/'
  },
  CORE: {
    IPS: '/api/core/ips/',
    CITIES: '/api/core/cities/',
    NAV_BAR: '/api/core/nav-bar/',
    NEWS: '/api/core/news/'
  },
  MASSIVE_LOAD: {
    TEMPLATES: '/api/massive-load/templates/',
    FILE_REQUESTS: '/api/massive-load/file-requests/',
    PRESIGNED_URLS: '/api/massive-load/presigned-urls/',
    REQUEST_RESULTS: '/api/massive-load/request-results/',
    REQUESTS_CSV: '/api/massive-load/requests/csv/',
    REQUESTS_TXT: '/api/massive-load/requests/txt/',
    REQUESTS_RAR: '/api/massive-load/requests/rar/',
    REQUESTS_XLS: '/api/massive-load/requests/xls/',
    REQUESTS_XLSX: '/api/massive-load/requests/xlsx/',
    REQUESTS_ZIP: '/api/massive-load/requests/zip/',
    REQUESTS_S3: '/api/massive-load/requests/s3/'
  },
  PEOPLE: {
    ADDRESS: '/api/people/adrress/',
    DOCUMENT: '/api/people/document/',
    DOCUMENT_TYPE: '/api/people/document_type/',
    EMAIL: '/api/people/emails/',
    INACTIVATE_REASON: '/api/people/inactive-reason/',
    INSURANCE_PLANS: '/api/people/insurance-plans/',
    PATIENT: '/api/people/patient/',
    BUKEALA_UPDATE: '/api/people/bukeala-patient-phone/',
    PATIENT_INACTIVATE_REASON: '/api/people/patient-inactive-reason/',
    PATIENT_WITH_PENDING_AUTHORIZATION:
      '/api/people/patient-pendient-authorization/',
    PATIENTS_WITH_NO_CYCLE: '/api/people/patient_filter_no_cycle/',
    PHONE: '/api/people/phone/',
    REPROGRAMMING: '/api/people/re-programming/',
    VALIDATION_PEOPLE_VALIDATION_ADMISSIONS:
      '/api/people/billing-validation-admissions/',
    VALIDATION_PEOPLE_PHARMACY: '/api/people/billing-validation-pharmacys/',
    VALIDATION_PEOPLE_BILLING: '/api/people/billing-validation-bills/',
    VALIDATION_PEOPLE_ROOMS: '/api/people/billing-validation-rooms/',
    VALIDATION_PEOPLE_MIXING: '/api/people/billing-validation-mixing-plant/'
  },
  POWER_BI: {
    DASHBOARD: (dashboardUUID) => `/api/power_bi/reports/${dashboardUUID}/`
  },
  REPORT: {
    REQUEST: '/api/report/request/',
    REQUESTS: '/api/report/requests/'
  },
  REPORTS: {
    DYNAMIC_REPORTS: '/api/report/dynamic-reports/', // TODO: Pending Endpoint
    REPORTS: '/api/report/custom-report/',
    REQUESTS: '/api/report/requests/'
  },
  UFCA: {
    ANSWER: '/api/ufcas/answer/',
    ANSWERS: '/api/ufcas/answers/',
    ANSWERS_SOURCE: 'api/ufcas/answers-source/',
    SURVEYS: '/api/ufcas/surveys/',
    SURVEYS_DETAIL: '/api/ufcas/surveys-detail/',
    SURVEY_MODULES: '/api/ufcas/modules/',
    SURVEY_FINALIZED_FORM: '/api/ufcas/finalized-form/',
    SURVEY_PATIENT: 'api/ufcas/survey-patient/',
    SURVEY_PATIENT_ANSWERS: '/api/ufcas/survey-patient-answers/',
    OPTIONS: '/api/ufcas/options/',
    PATIENT_BASE: '/api/people/patient-base/',
    PATIENT_BASE_WITH_DOCUMENT: '/api/people/patient-base-with-document/',
    QUESTIONS: '/api/ufcas/question/',
    QUESTION_FILTERS: '/api/ufcas/question-filters/'
  },
  TENANTS: {
    AUTHENTICATE_TENANTS: '/api/tenants/authenticate-tenants/'
  }
};

export const FAILED_SHOWDATA_REQUEST_STATE = {
  data: [],
  totalCount: 0,
  isSearching: false
};
