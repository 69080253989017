/* eslint-disable react/prop-types */
//* External
import { omit } from 'lodash';
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

//* App Custom
import { tnl } from '@i18nApp/i18n';
import { cleanObject, obtainParams, verifyData } from '@utilsApp/index';
import { Button, InputHandler } from '../index';
import './offCanvasForm.scss';

// Add the name(s) of the filter(s) to be rendered outside Offcanvas.
const ALWAYS_VISIBLE_INPUTS = ['document_number'];

const Form = ({
  type,
  inputs = [],
  onSubmit = () => {},
  showFilterButton = false,
  onFilterClick = () => {}
}) => {
  const { control, handleSubmit } = useForm();
  const { isDoingRequest } = useSelector((state) => state.commonState);

  return (
    <>
      {inputs.length > 0 && (
        <div className="searchRequest">
          <form onSubmit={handleSubmit(onSubmit)} className="row">
            {inputs
              .filter((input) => !input?.isHidden)
              .map((input) => (
                <InputHandler
                  key={input.name}
                  {...input}
                  label={type === 'offcanvas' ? input.label : ''}
                  control={control}
                  design={
                    type !== 'offcanvas'
                      ? `col-12 col-md-6 ${
                          input.type === 'date-range' ? 'col-lg-6' : 'col-lg-3'
                        } mb-3`
                      : 'col-12 mb-3'
                  }
                />
              ))}
            <Button
              type="submit"
              variant="secondary"
              border="none"
              title={type !== 'offcanvas' ? 'buttons.filter' : ''}
              text={type === 'offcanvas' ? 'buttons.filter' : ''}
              iconName="magnifyingGlass"
              disabled={isDoingRequest}
              fullWidth={type === 'offcanvas'}
            />
            {showFilterButton && (
              <div className="col-auto">
                <Button
                  variant="secondary"
                  border="none"
                  text="labels.filters"
                  iconName="filter"
                  onClick={onFilterClick}
                />
              </div>
            )}
          </form>
        </div>
      )}
    </>
  );
};

const OffCanvasForm = ({
  inputs = () => [],
  inputParams = {},
  defaultValues = {},
  onSubmit = () => {}
}) => {
  const [show, setShow] = useState(false);
  const [newDefaultValues, setNewDefaultValues] = useState(
    omit(defaultValues, ['length', 'page'])
  );

  const inputsToRender = inputs({
    ...inputParams,
    defaultValues: newDefaultValues
  });

  const getFilteredInputs = () => {
    const visibleInputs = inputsToRender.filter((input) =>
      ALWAYS_VISIBLE_INPUTS.includes(input.name)
    );
    const offcanvasInputs = inputsToRender.filter(
      (input) => !ALWAYS_VISIBLE_INPUTS.includes(input.name)
    );
    return { visibleInputs, offcanvasInputs };
  };

  const formSubmit = (data) => {
    const cleanData = cleanObject(data);
    const paramsForVisual = obtainParams(cleanData, 'label');
    const paramsForRequest = obtainParams(cleanData, 'value');
    setNewDefaultValues(paramsForVisual);
    onSubmit(paramsForRequest);
  };

  const transformValue = (value) => {
    if (typeof value === 'string' && value.includes(',')) {
      return value
        .split(',')
        .map((item) => tnl(item))
        .join(', ');
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      return Object.entries(value)
        .map(([key, val]) =>
          val !== undefined ? `${tnl(`labels.${key}`)}: ${val}` : null
        )
        .filter((item) => item !== null)
        .join(', ');
    } else {
      return tnl(value);
    }
  };

  const { visibleInputs, offcanvasInputs } = getFilteredInputs();

  return (
    <>
      {inputsToRender.length <= 3 ? (
        <div className="col-12 searchRequest">
          <Form type="simple" inputs={inputsToRender} onSubmit={formSubmit} />
        </div>
      ) : (
        <>
          <div className="d-flex flex-column gap-3">
            {visibleInputs.length > 0 && (
              <Form
                type="simple"
                inputs={visibleInputs}
                onSubmit={formSubmit}
                showFilterButton={true}
                onFilterClick={() => setShow(true)}
              />
            )}
            {visibleInputs.length === 0 && (
              <div className="col-auto">
                <Button
                  variant="secondary"
                  border="none"
                  text="labels.filters"
                  iconName="filter"
                  onClick={() => setShow(true)}
                />
              </div>
            )}
            {Object.keys(newDefaultValues).length > 0 && (
              <div className="d-flex flex-wrap gap-2">
                {Object.entries(newDefaultValues).map(([key, value], index) => {
                  const transformedValue = transformValue(value);
                  if (!transformedValue) return null;
                  return (
                    <div
                      key={`filter_${index}`}
                      className="d-flex gap-1 offcanvas_applied_filter"
                    >
                      {verifyData(
                        [tnl(`labels.${key}`), transformedValue],
                        ': '
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <Offcanvas
            show={show}
            onHide={() => setShow(false)}
            placement="start"
            name="start"
            className="custom-width"
          >
            <div className="p-3">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title className="fw-bold offcanvas_title">
                  {tnl('labels.filters')}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div
                  style={{
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    maxHeight: '85vh'
                  }}
                >
                  <Form
                    type="offcanvas"
                    inputs={offcanvasInputs}
                    onSubmit={formSubmit}
                  />
                </div>
              </Offcanvas.Body>
            </div>
          </Offcanvas>
        </>
      )}
    </>
  );
};

export default OffCanvasForm;
