//* External
import { find } from 'lodash';

//* App Custom
import {
  FILE_EXTENSIONS,
  emptyInputOptions,
  formOptions
} from '@constantsApp/index';
import { DATE_VALID_PATTERN, INPUT_REQUIRED } from '@constantsApp/inputRules';
import { REGEX_OPTIONS, formatDateForInput } from '@utilsApp/index';

const generateInputKey = (type = '', uniqueValue) => `${type}_${uniqueValue}`;

const phoneOptions = formOptions(['MOBILE', 'TELEPHONE']);
export const phoneInputs = (inputData) => [
  {
    key: generateInputKey('number_type', inputData.id),
    type: 'select',
    name: `number_type`,
    placeholder: 'placeholders.phone_type',
    options: phoneOptions,
    defaultValue: find(phoneOptions, { value: inputData?.number_type }),
    rules: INPUT_REQUIRED,
    design: 'col-12 col-md-6 pe-0'
  },
  {
    key: generateInputKey('number', inputData.id),
    type: 'text',
    name: `number`,
    placeholder: 'placeholders.phone_number',
    defaultValue: inputData?.number,
    rules: {
      required: { value: true },
      pattern: {
        value: /^(?:\d{7}|3\d{9})$/,
        message: 'form.error-phone-pattern'
      }
    },
    design: 'col-12 col-md-6 ps-0'
  }
];

export const medicalOrderTypes = [
  { label: 'MEDICAMENTO', value: 1 },
  { label: 'PROCEDIMIENTO', value: 2 }
];

const serviceProvidersOptions = [
  { label: 'CLINICA COLOMBIA', value: 9631 },
  { label: 'CLINICA SEBASTIAN DE BELARCAZAR', value: 9563 },
  { label: 'CLINICA CARMEN', value: 172651 }
];

export const medicalOrderInputs = (
  inputData,
  index,
  {
    services,
    medicalOrderTypeSelected,
    obtainServices,
    setMedicalOrderTypeSelected,
    watch
  }
) =>
  [
    {
      key: generateInputKey('order_date', inputData.id),
      type: 'date',
      name: 'order_date',
      label: 'labels.order_date',
      defaultValue: inputData?.order_date,
      max: formatDateForInput(new Date()),
      rules: DATE_VALID_PATTERN
    },
    {
      key: generateInputKey('order_type', inputData.id),
      type: 'select',
      name: 'order_type',
      label: 'labels.order_type',
      placeholder: 'placeholders.order_type',
      options: medicalOrderTypes,
      defaultValue: find(medicalOrderTypes, { value: inputData?.order_type }),
      onEventChange: (type) => {
        setMedicalOrderTypeSelected(type);
        obtainServices(type);
      }
    },
    {
      key: generateInputKey('service', inputData.id),
      type: 'select',
      name: 'service',
      label: 'labels.order_code',
      placeholder: 'placeholders.order_code',
      options: services,
      defaultValue: find(services, { value: inputData?.service }),
      onInputChange: (search) => {
        if (search) {
          obtainServices(medicalOrderTypeSelected, { search });
        }
      },
      disabled: emptyInputOptions.includes(
        watch(`medical_orders[${index}].order_type`)
      )
    },
    {
      key: generateInputKey('service_provider', inputData.id),
      type: 'select',
      name: 'service_provider',
      label: 'labels.service_provider',
      placeholder: 'placeholders.service_provider',
      options: serviceProvidersOptions
    },
    {
      key: generateInputKey('medical_support', inputData.id),
      type: 'dropzone',
      name: 'medical_support',
      label: 'labels.medical_support',
      extensions: [
        FILE_EXTENSIONS.JPG,
        FILE_EXTENSIONS.PDF,
        FILE_EXTENSIONS.PNG,
        FILE_EXTENSIONS.RAR,
        FILE_EXTENSIONS.ZIP
      ],
      rules: {
        required: { value: false }
      }
    },
    {
      key: generateInputKey('weight', inputData.id),
      type: 'text',
      name: 'weight',
      label: 'labels.weight',
      placeholder: 'placeholders.weight',
      rules: {
        required: { value: false },
        pattern: {
          value: REGEX_OPTIONS.number_valid,
          message: 'form.error-number-pattern'
        }
      }
    },
    {
      key: generateInputKey('size', inputData.id),
      type: 'text',
      name: 'size',
      label: 'labels.size',
      placeholder: 'placeholders.size',
      rules: {
        required: { value: false },
        pattern: {
          value: REGEX_OPTIONS.size_valid,
          message: 'form.error-size-pattern'
        }
      }
    }
  ].map((input) => ({
    ...input,
    rules: { required: { value: true }, ...input?.rules },
    design: 'col-12 col-md-6'
  }));

export const cumInputs = (inputData, { services, obtainServices }) =>
  [
    {
      key: generateInputKey('order_date', inputData.id),
      type: 'date',
      name: 'order_date',
      label: 'labels.order_date',
      defaultValue: inputData?.order_date,
      max: formatDateForInput(new Date()),
      rules: DATE_VALID_PATTERN
    },
    {
      key: generateInputKey('service', inputData.id),
      type: 'select',
      name: 'service',
      label: 'labels.order_code',
      placeholder: 'placeholders.order_code',
      options: services,
      defaultValue: find(services, { value: inputData?.service }),
      onInputChange: (search) => {
        if (search) obtainServices(medicalOrderTypes[0], { search });
      }
    },
    {
      key: generateInputKey('service_provider', inputData.id),
      type: 'select',
      name: 'service_provider',
      label: 'labels.service_provider',
      placeholder: 'placeholders.service_provider',
      options: serviceProvidersOptions
    },
    {
      key: generateInputKey('medical_support', inputData.id),
      type: 'dropzone',
      name: 'medical_support',
      label: 'labels.medical_support',
      extensions: [
        FILE_EXTENSIONS.JPG,
        FILE_EXTENSIONS.PDF,
        FILE_EXTENSIONS.PNG,
        FILE_EXTENSIONS.RAR,
        FILE_EXTENSIONS.ZIP
      ]
    },
    {
      key: generateInputKey('weight', inputData.id),
      type: 'text',
      name: 'weight',
      label: 'labels.weight',
      placeholder: 'placeholders.weight',
      rules: {
        required: { value: false },
        pattern: {
          value: REGEX_OPTIONS.number_valid,
          message: 'form.error-number-pattern'
        }
      }
    },
    {
      key: generateInputKey('size', inputData.id),
      type: 'text',
      name: 'size',
      label: 'labels.size',
      placeholder: 'placeholders.size',
      rules: {
        required: { value: false },
        pattern: {
          value: REGEX_OPTIONS.size_valid,
          message: 'form.error-size-pattern'
        }
      }
    }
  ].map((input) => ({
    ...input,
    rules: { required: { value: true }, ...input?.rules },
    design: 'col-12 col-md-6'
  }));
