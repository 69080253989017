//* External
import { find } from 'lodash';

//* App Custom
import { DATE_VALID_PATTERN } from '@constantsApp/index';
import {
  createDropdownOptions,
  formatDateForInput,
  verifyData
} from '@utilsApp/index';

export const searchPatientInputs = ({ originOptions, documentTypes }) => {
  const originOptionsFormatted = createDropdownOptions(originOptions);
  return [
    {
      type: 'select',
      name: 'origin',
      label: 'labels.origin',
      placeholder: 'placeholders.origin',
      options: originOptionsFormatted,
      defaultValue: find(originOptionsFormatted, { value: 'AVICENA' }),
      design: 'col-12'
    },
    {
      type: 'select',
      name: 'document_type',
      label: 'labels.document_type',
      placeholder: 'placeholders.document_type',
      options: documentTypes
    },
    {
      type: 'text',
      name: 'document_number',
      label: 'labels.document_number',
      placeholder: 'placeholders.document_number',
      rules: {
        pattern: {
          value: /^[0-9]*$/g,
          message: 'form.error-document-pattern'
        }
      }
    }
  ].map((input) => ({
    ...input,
    design: input?.design || 'col-lg-6',
    rules: {
      required: { value: true },
      ...input?.rules
    }
  }));
};

export const patientInputs = ({
  patientData,
  documentTypes,
  cities,
  obtainCities
}) =>
  [
    {
      type: 'text',
      name: 'names',
      label: 'labels.names',
      placeholder: 'placeholders.names'
    },
    {
      type: 'text',
      name: 'last_names',
      label: 'labels.last_names',
      placeholder: 'placeholders.last_names'
    },
    {
      type: 'select',
      name: 'document_type',
      label: 'labels.document_type',
      placeholder: 'placeholders.document_type',
      options: documentTypes,
      defaultValue: documentTypes.find(
        (type) => type.value.pk === patientData?.documents?.[0]?.document_type
      ),
      disabled: true
    },
    {
      type: 'text',
      name: 'document_number',
      label: 'labels.document_number',
      placeholder: 'placeholders.document_number',
      defaultValue: patientData?.documents?.[0]?.number,
      disabled: true
    },
    {
      type: 'text',
      name: 'emails.[0].email',
      label: 'labels.email',
      placeholder: 'placeholders.email',
      defaultValue: patientData?.emails?.[0]?.email,
      disabled: patientData?.emails?.length > 0
    },
    {
      type: 'select',
      name: 'gender',
      label: 'labels.gender',
      placeholder: 'placeholders.gender',
      options: createDropdownOptions(['MALE', 'FEMALE'])
    },
    {
      type: 'date',
      name: 'birth_date',
      label: 'labels.birth_date',
      rules: DATE_VALID_PATTERN
    },
    {
      type: 'select',
      name: 'geo_city_id',
      label: 'labels.city',
      placeholder: 'placeholders.city',
      options: cities,
      onInputChange: (search) => {
        search && obtainCities({ search });
      }
    },
    {
      type: 'text',
      name: 'addresses.[0].address',
      label: 'labels.address',
      placeholder: 'placeholders.address',
      defaultValue: patientData?.addresses?.[0]?.address,
      disabled: patientData?.addresses?.length > 0
    },
    {
      type: 'select',
      name: 'insurance_plans[0]',
      label: 'labels.plan',
      placeholder: 'placeholders.plan',
      options: [
        {
          label: 'PARTICULAR',
          value: {
            code: 1500,
            name: 'PARTICULAR',
            reason: `Paciente creado de forma PARTICULAR`,
            start_date: formatDateForInput(new Date()),
            status: 'HABILITADO'
          }
        },
        {
          label: 'INVESTIGACION',
          value: {
            code: 1501,
            name: 'INVESTIGACION',
            reason: `Paciente creado de forma INVESTIGACION`,
            start_date: formatDateForInput(new Date()),
            status: 'HABILITADO'
          }
        }
      ]
    }
  ].map((input) => ({
    ...input,
    design: input?.design || 'col-12 col-md-6',
    rules: { required: { value: true }, ...input?.rules }
  }));

export const patientInfoObtained = (patientData) => [
  {
    label: 'labels.names',
    value: verifyData([patientData?.first_name, patientData?.second_name])
  },
  {
    label: 'labels.last_names',
    value: verifyData([
      patientData?.first_last_name,
      patientData?.second_last_name
    ])
  },
  {
    label: 'labels.document',
    value: verifyData([
      patientData?.document_type,
      patientData?.documents?.[0]?.number
    ])
  },
  {
    label: 'IPS',
    value: verifyData([patientData?.service_provider_name])
  },
  {
    label: 'labels.state',
    value: verifyData([patientData?.state])
  },
  {
    label: 'labels.city',
    value: verifyData([patientData?.city])
  }
];
