//* External
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { es } from 'date-fns/locale';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

//* App Custom
import { tnl } from '@i18nApp/i18n';
import { formatDate } from '@utilsApp/index';
import InputLabel from '../InputLabel/InputLabel';
import './inputDateRange.scss';
import { Button } from '@componentsApp/common';

const InputDateRange = ({
  name,
  label,
  labelHelper,
  nameStartDate,
  nameEndDate,
  defaultValueStartDate,
  defaultValueEndDate,
  disabled = false,
  required = false,
  min,
  max,
  hookError,
  design = 'col-12',
  onInputChange = () => {},
  hookOnChange = () => {}
}) => {
  const calendarRef = useRef(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectingEndDate, setSelectingEndDate] = useState(false);

  const debouncedSetValue = debounce((value) => {
    hookOnChange(value);
    onInputChange(value);
  }, 500);

  const initialRange = {
    startDate: defaultValueStartDate
      ? new Date(defaultValueStartDate)
      : undefined,
    endDate: defaultValueEndDate ? new Date(defaultValueEndDate) : undefined,
    key: 'selection'
  };

  const [state, setState] = useState([initialRange]);
  const isRangeEmpty = !state[0].startDate && !state[0].endDate;
  const currentDate = new Date();

  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;

    if (!selectingEndDate) {
      setState([{ startDate, endDate: state[0].endDate, key: 'selection' }]);
      setSelectingEndDate(true);
    } else {
      const updatedState = [{ startDate, endDate, key: 'selection' }];
      setState(updatedState);
      setShowCalendar(false);

      debouncedSetValue({
        [nameStartDate]: startDate ? formatDate(startDate, 'YYYY-MM-DD') : '',
        [nameEndDate]: endDate ? formatDate(endDate, 'YYYY-MM-DD') : ''
      });
    }
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setShowCalendar(false);
      setSelectingEndDate(false);
    }
  };

  const handleReset = () => {
    setState([initialRange]);
    debouncedSetValue({
      [nameStartDate]: undefined,
      [nameEndDate]: undefined
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`customInput ${design} ${
        disabled ? 'customInput_disabled' : ''
      }`}
    >
      {label && (
        <InputLabel
          name={name}
          text={label}
          helper={labelHelper}
          required={required}
        />
      )}
      <div className="customInput_container px-2">
        <Button
          variant="link"
          iconName="trashFill"
          title="labels.date_reset"
          onClick={handleReset}
          disabled={isRangeEmpty}
        />
        <p
          className={`fw-bold ${
            !selectingEndDate && showCalendar ? 'highlight' : ''
          }`}
        >
          {tnl('labels.start_date_range')}:
        </p>
        <input
          type="date"
          id="start-date"
          name="start-date"
          value={
            state[0].startDate
              ? formatDate(state[0].startDate, 'YYYY-MM-DD')
              : ''
          }
          readOnly
          onFocus={() => {
            setShowCalendar(true);
            setSelectingEndDate(false);
          }}
          disabled={disabled}
          required={required}
          className="custom-input"
        />
        <p
          className={`fw-bold ${
            selectingEndDate && showCalendar ? 'highlight' : ''
          }`}
        >
          {tnl('labels.end_date_range')}:
        </p>
        <input
          type="date"
          id="end-date"
          name="end-date"
          value={
            state[0].endDate ? formatDate(state[0].endDate, 'YYYY-MM-DD') : ''
          }
          readOnly
          onFocus={() => {
            setShowCalendar(true);
            setSelectingEndDate(true);
          }}
          disabled={disabled}
          required={required}
          className="custom-input"
        />
        {showCalendar && (
          <div className="calendar-wrapper" ref={calendarRef}>
            <DateRange
              ranges={[
                {
                  startDate: isRangeEmpty ? currentDate : state[0].startDate,
                  endDate: isRangeEmpty ? currentDate : state[0].endDate,
                  key: 'selection'
                }
              ]}
              onChange={handleSelect}
              showDateDisplay={false}
              editableDateInputs={true}
              moveRangeOnFirstSelection={true}
              minDate={min ? new Date(min) : undefined}
              maxDate={max ? new Date(max) : undefined}
              focusedRange={selectingEndDate ? [0, 1] : [0, 0]}
              rangeColors={[
                isRangeEmpty
                  ? 'var(--app-primary-color)'
                  : 'var(--app-primary-color)'
              ]}
              locale={es}
            />
          </div>
        )}
      </div>
      {hookError?.message && (
        <p className="customInput_error">{tnl(hookError?.message)}</p>
      )}
    </div>
  );
};

InputDateRange.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  nameStartDate: PropTypes.string,
  nameEndDate: PropTypes.string,
  placeholderStartDate: PropTypes.string,
  placeholderEndDate: PropTypes.string,
  labelHelper: PropTypes.string,
  defaultValueStartDate: PropTypes.string,
  defaultValueEndDate: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  design: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  hookError: PropTypes.shape({
    message: PropTypes.string
  }),
  onInputChange: PropTypes.func,
  hookOnChange: PropTypes.func
};

export default InputDateRange;
